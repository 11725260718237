import React from 'react';

export function Try() {
  return (
    <section className="container flex px-2 py-16 lg:py-20 try-testimonials">
      <div className="flex flex-col lg:flex-row lg:justify-around lg:w-full ">
        <h3 className="flex uppercase text-3xl mt-1 pb-4 lg:pb-0 font-extrabold ">
          Wypróbuj za darmo
        </h3>
        <form
          action="https://app.fakturuj.to"
        >
          <button
            type="submit"
            className="flex mx-auto leading-loose justify-between w-40 py-3 pl-6 pr-4 bg-white hover:shadow-none try-item font-bold text-white uppercase rounded-full shadow-lg"
          >
            <p className="flex-row flex items-center">Sprawdzam
              <i className="mx-2 fa fa-chevron-right"/>
            </p>
          </button>
        </form>
      </div>
    </section>
  );
}
Try.defaultProps = {};
Try.propTypes = {};
