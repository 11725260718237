import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { CloseButton, Modal } from '../../Modal/Modal';

export const Videos = ({}) => {

  const { marekScreenShot, sylwiaScreenShot } = useStaticQuery(graphql`
      query {
          marekScreenShot: file(relativePath: { eq: "video-screenshots/marekRowery.jpeg" }) {
              ...thumbnailImage
          }
          sylwiaScreenShot: file(relativePath: { eq: "video-screenshots/sylwiaCopywriter.jpeg" }) {
              ...thumbnailImage
          }
      }
  `)

  const data = [
    {
      id: "1",
      message: "Sprawdź opinię Marka - właściciela Buga Sport",
      content: (
        <iframe
          className="video-testimonial-content"
          src="//player.vimeo.com/video/372332846?autoplay=1"
          allowFullScreen
        />
      ),
      image: marekScreenShot.childImageSharp.fluid,
    },
    {
      id: "2",
      message: "Sprawdź opinię Sylwii - Social Media Marketing Freelancerki",
      content: (
        <iframe
          className="video-testimonial-content"
          src="//player.vimeo.com/video/321678656?autoplay=1"
          allowFullScreen
        />
      ),
      image: sylwiaScreenShot.childImageSharp.fluid,
    },
  ]

  return (
    <div className="flex flex-col sm:flex-row">
      {data.map(dataItem => {
        const [isOpen, setIsOpen] = React.useState(false)
        return (
          <div
            key={dataItem.id}
            className="container flex flex-col px-4"
          >
            <div className="p-4">
              <BackgroundImage
                fluid={dataItem.image}
                style={{
                  height: `${(1 / dataItem.image.aspectRatio * 100)}vw`,
                }}
                className="video-screenshot-player h-64"
                onClick={() => setIsOpen(!isOpen)}
              >
                <button
                  className="icon"
                  aria-label="referral move"
                >
                  <i className="fa fa-play-circle"/>
                </button>
              </BackgroundImage>
            </div>
            <p>
              {dataItem.message}
            </p>
            <Modal
              isOpen={isOpen}
              onRequestClose={() => setIsOpen(false)}
            >
              <CloseButton onClick={() => setIsOpen(false)}/>
              {dataItem.content}
            </Modal>
          </div>
        )
      })}
    </div>
  );
}
