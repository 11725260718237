import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Logo } from './Logo';
import Img from 'gatsby-image';

export const MainPageHeader = () => {
  const data = useStaticQuery(graphql`
      query MainPageHeaderQuery {
          hero: file(relativePath: { eq: "hero.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1665, maxHeight: 900, base64Width: 10, fit: COVER, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `);

  const [menuVisible, setMenuVisible] = React.useState(false);
  const menuRef = React.useRef();
  const buttonRef = React.useRef();

  const handleClickOutside = e => {
    if (buttonRef.current.contains(e.target)) {
      return;
    }

    if (!menuRef.current.contains(e.target)) {
      setMenuVisible(false);
    }
  };

  const handleClick = () => setMenuVisible(!menuVisible);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <>
      <nav
        id="header"
        className="absolute w-full top-0 text-white bg-transparent"
        style={{
          zIndex: 2,
        }}
      >
        <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
          <div className="pl-4 flex items-center">
            <Logo
              white
              className="h-6 p-1 ml-2"
            />
          </div>
          <div className="block md:hidden pr-4">
            <button
              id="nav-toggle"
              name="toggled navbar"
              aria-hidden="true"
              className="w-full flex-grow md:flex md:items-center md:w-auto mt-0 md:bg-transparent p-2 md:p-0 text-2xl"
              style={{ zIndex: 2 }}
              onClick={handleClick}
              ref={buttonRef}
            >
              <i className="fa fa-bars" aria-hidden="true"/>
            </button>
          </div>
          <div
            ref={menuRef}
            className={`w-full flex-grow md:flex md:items-center md:w-auto ${(menuVisible ? 'block' :
              'hidden')} md:block mt-2 md:mt-0 bg-white md:bg-transparent text-black p-4 md:p-0`}
            style={{ zIndex: 2 }}
            id="nav-content"
          >
            <ul
              className="list-reset md:flex justify-end flex-1 items-center uppercase text-black md:text-white font-bold no-underline"
            >
              <li className="mr-3">
                <a className="inline-block py-2 px-4" href="/kariera" onClick={() => setMenuVisible(false)}
                >Kariera</a>
              </li>
              <li className="mr-3">
                <a className="inline-block py-2 px-4" href="#dlaczego" onClick={() => setMenuVisible(false)}
                >Dlaczego?</a>
              </li>
              <li className="mr-3">
                <a className="inline-block py-2 px-4" href="#cennik" onClick={() => setMenuVisible(false)}>Cennik</a>
              </li>
              <li className="mr-3">
                <a className="inline-block py-2 px-4" href="https://app.fakturuj.to"
                   onClick={() => setMenuVisible(false)}
                >Logowanie</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div
        className="flex flex-col items-center relative h-1 overflow-hidden"
        style={{
          height: `${(1 / data.hero.childImageSharp.fluid.aspectRatio * 100)}vw`,
          minHeight: '300px',
          maxHeight: '700px',
        }}
      >
        <div
          className="absolute top-0 walden w-full h-full bg-auto bg-contain flex flex-col items-center"
          style={{ filter: 'brightness(1.1) hue-rotate(-10deg) sepia(.3) saturate(1.6)' }}
        >
          <Img
            className="w-full h-full"
            fluid={data.hero.childImageSharp.fluid}
            fadeIn={false}
            critical={true}
          />
        </div>
        <div
          className="absolute top-0 bottom-0 left-0 right-0 container p-3 mx-auto flex flex-wrap flex-row items-center"
        >
          <div className="flex flex-col w-full justify-center items-center">
            <h1 className="md:my-4 font-bold leading-tight uppercase text-white">
              Witamy na
            </h1>
            <Logo
              style={{ width: '50%' }}
              className="m-2"
            />
            <p
              className="hidden lg:block text-shadow-black px-4 py-1 my-2 text-sm md:text-lg font-hairline text-white"
            >
              Niezależnie czy chcesz wystawić fakturę sprzedaży czy fakturę zaliczkę,<br/>
              zawsze zrobisz to w sposób intuicyjny i ekspresowy!
            </p>
            <form
              action="https://app.fakturuj.to/szybka-rejestracja"
            >
              <button
                type="submit"
                className="mx-auto lg:mx-0 hover:underline bg-red-600 font-bold rounded-full
                  my-4 py-4 px-8 md:my-6 md:py-6 md:px-10 shadow-lg text-white uppercase"
              >
                Zakładam darmowe konto
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="block mx-auto pt-8 lg:hidden container flex flex-row items-center justify-center">
        <p className="px-4 py-1 my-2 text-sm md:text-lg font-hairline max-w-6xl text-center">
          Niezależnie czy chcesz wystawić fakturę sprzedaży czy fakturę zaliczkę,<br/>
          zawsze zrobisz to w sposób intuicyjny i ekspresowy!
        </p>
      </div>
    </>
  );
};
