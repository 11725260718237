import React from 'react';

import Layout from '../components/layout';
import { Opinions } from '../components/index-sections/opinions/Opinions';
import { Section } from '../components/Section';
import { Videos } from '../components/index-sections/videos/Videos';
import { Pricing } from '../components/index-sections/pricing/Pricing';
import { Introduction } from '../components/index-sections/introduction/Introduction';
import { Screenshots } from '../components/index-sections/Screenshots';
import { Try } from '../components/index-sections/try/Try';
import { Description } from '../components/index-sections/description/Description';
import { Seo } from '../components/Seo';
import { CompanyInfo } from '../components/index-sections/main-page-footer/CompanyInfo';
import { CompanySummary } from '../components/index-sections/main-page-footer/CompanySummary';

function IndexPage() {
  return (
    <Layout hero>
      <Seo
        title="FAKTURUJ.TO"
        description="FAKTURUJ.TO prosty program do wystawiania faktur oraz przygotowywania raportów kasowych."
        keywords={['faktury', 'faktury cykliczne', 'raporty kasowe']}
        meta={[{ name: `facebook-domain-verification`, content: '3ax7juekehou39wj83ptxfy04f0zg1' }]}
      />
      <Section title={'FAKTURUJ.TO'}>
        <Description/>
      </Section>
      <Section
        title={'Dlaczego fakturuj.to?'}
        id={'dlaczego'}
        className="introduce-testimonials"
      >
        <Introduction/>
      </Section>
      <Section
        title={'Co myślą nasi klienci?'}
        className="video-testimonials"
      >
        <Videos/>
      </Section>
      <Section title={'Screenshoty'}>
        <Screenshots/>
      </Section>
      <Try/>
      <Section title={'Opinie użytkowników'}>
        <Opinions/>
      </Section>
      <Section
        title={'Cennik'}
        id={'cennik'}
      >
        <Pricing/>
        <div className="flex flex-col lg:flex-row lg:justify-around lg:w-full mt-10">
          <form
            action="/cennik"
          >
            <button
              type="submit"
              className="flex mx-auto leading-loose justify-between py-3 pl-6 pr-4 bg-white hover:shadow-none try-item font-bold text-white uppercase rounded-full shadow-lg"
            >
              <p className="flex-row flex items-center">
                szczegółowy cennik
                <i className="mx-2 fa fa-chevron-right"/>
              </p>
            </button>
          </form>
        </div>
      </Section>
      <CompanySummary/>
      <CompanyInfo
        id={'kontakt'}
      />
    </Layout>
  );
}

export default IndexPage;
