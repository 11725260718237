import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const data = [
  {
    id: '1',
    title: 'Wszędzie',
    content: 'FAKTURUJ.TO to prosty i intuicyjny program do wystawiania faktur oraz przygotowywania raportów kasowych oparty w całości na pracy w Twojej przeglądarce.',
    icon: <i className="fa fa-plane"/>,
  },
  {
    id: '2',
    title: 'Intuicyjność',
    content: 'Niezależnie czy chcesz wystawić fakturę sprzedaży czy fakturę zaliczkę, zawsze zrobisz to w sposób prosty i ekspresowy, bez zbędnych instalacji czy długich konfiguracji.',
    icon: <i className="fa fa-sort-alpha-asc"/>,
  },
  {
    id: '3',
    title: 'Innowacja',
    content: 'Cały czas rozwijamy się dla Ciebie pracując nad nowymi funkcjonalnościami, które sukcesywnie będą dodawane do istniejących pakietów.',
    icon: <i className="fa fa-code"/>,
  },
];

export function Description() {
  const {monitor} = useStaticQuery(graphql`
      query {
          monitor: file(relativePath: { eq: "monitor.png" }) {
              childImageSharp {
                  fixed(width: 555, height: 511, fit: CONTAIN, background: "rgba(0,0,0,0)") {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `);

  return (
    <div className="container flex flex-col">
      <div className="container mx-auto py-0 px-4 h-24">
        <p className="content-color">FAKTURUJ.TO prosty program do wystawiania faktur oraz przygotowywania raportów kasowych.</p>
      </div>
      <div className="container flex flex-row">
        <div className="container mx-auto flex flex-col w-full lg:w-1/2 py-0 px-4 lg:justify-between">
          {data.map((item) => (
            <div
              key={item.id}
              className="flex flex-col-reverse lg:flex-row py-6 px-5 lg:pr-6 lg:justify-around items-center"
            >
              <div
                className="flex-col lg:text-right lg:w-5/6"
              >
                <div className="text-lg font-bold mt-3 mb-3 md:mt-0">
                  {item.title}
                </div>
                <p className="content-color">
                  {item.content}
                </p>
              </div>
              <div className="lg:w-3 pb-4 lg:pb-0">
                <div
                  className="rounded-full h-16 w-16 flex items-center justify-center shadow-lg w-20 h-20 icon-circle"
                >
                  <p className="text-3xl ">
                    {item.icon}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="hidden lg:block lg:w-1/2 py-0 px-4">
          <Img
            fixed={monitor.childImageSharp.fixed}
            critical={true}
            fadeIn={false}
          />
        </div>
      </div>
    </div>
  );
}

Description.defaultProps = {};

Description.propTypes = {};
