import React from "react";
import PropTypes from "prop-types";
import { CdnHelmet } from "./CdnHelmet";
import { MainPageHeader } from "./MainPageHeader";
import { Footer } from "./Footer";
import { useUserIsTabbingHook } from "../utils/useUserIsTabbingHook";


const Layout = ({children}) => {
  useUserIsTabbingHook();

  return (
    <div className="leading-normal tracking-normal">
      <CdnHelmet/>
      <MainPageHeader/>
      {children}
      <Footer/>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hero: PropTypes.bool,
  title: PropTypes.string,
};

export default Layout;
