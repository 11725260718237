import React from 'react';

const data = [
  {
    id: '1',
    icon: <i className="fa fa-cloud content-color"/>,
    title: 'Chmura',
    content: 'Wystawiaj faktury, gdzie tylko ich potrzebujesz. FAKTURUJ.TO jest rozwiązaniem całkowicie opartym na przeglądarce.',
  },
  {
    id: '2',
    icon: <i className="fa fa-expand content-color"/>,
    title: 'Prostota',
    content: 'FAKTURUJ.TO proste! Ekspresowo wystawisz dokument, przygotujesz raport kasowy czy ustawisz faktury cykliczne.',
  },
  {
    id: '3',
    icon: <i className="fa fa-thumbs-o-up content-color"/>,
    title: 'Intuicyjność',
    content: 'Do wystawiania dokumentów w FAKTURUJ.TO nie potrzebna jest instrukcja. Załóż konto i spróbuj!',
  },
  {
    id: '4',
    icon: <i className="fa fa-lock content-color"/>,
    title: 'Bezpieczeństwo',
    content: 'Twoje bezpieczeństwo jest dla nas najważniejsze, dlatego nie musisz udostępniać swojego loginu i hasła innymi osobom, zaproś ich po prostu jako dodatkowych użytkowników.',
  },
  {
    id: '5',
    icon: <i className="fa fa-tachometer content-color"/>,
    title: 'Stabilność',
    content: 'Do programu wprowadzamy tylko sprawdzone rozwiązania techniczne, dzięki czemu masz pewność wygody i niezawodności w użytkowaniu.',
  },
  {
    id: '6',
    icon: (
      <svg width={24} height={24} viewBox="0 0 24 24">
        <path fill="#555" stroke="#555" d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99l1.5 1.5z" />
      </svg>
    ),
    title: 'Rozwój',
    content: 'Cały czas się dla Ciebie rozwijamy. Każda informacja zwrotna zostanie przez nas zweryfikowana, zachęcamy do kontaktu. Już niedługo kolejne funkcjonalności!',
  },
];

export function Introduction() {
  return (
    <div className="container">
      <div className="container mx-auto py-0 px-4 h-24 mb-12">
        <p className="content-color">
          Niezależnie czy chcesz wystawić fakturę sprzedaży czy fakturę zaliczkę, zawsze zrobisz to w sposób intuicyjny
          i ekspresowy!
        </p>
      </div>
      <div className="flex flex-row flex-wrap">
        {data.map((item) => (
          <div
            key={item.id}
            className="px-3 flex flex-col w-full md:w-1/2 lg:w-1/3 h-64 mx-auto"
          >
            <div><p className="text-3xl pb-4 flex flex-1 items-center justify-center">{item.icon}</p></div>
            <div className="text-2xl font-bold mb-3">{item.title}</div>
            <div className="content-color">{item.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

Introduction.defaultProps = {};

Introduction.propTypes = {};
