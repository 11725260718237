import * as  React from 'react';

export const CompanyInfo: React.FC = () => (
    <section className="max-w-full flex justify-center mx-auto px-3 md:px-4 xl:px-8 text-left content-color main-page-footer-testimonials">
      <div className="flex container md:flex-row flex-col sm:mx-auto text-base md:text-sm distance-to-left">
        <div className="md:w-1/4 flex my-5">
          <p>
            Fakturuj sp. z o.o.<br/>
            ul. Jana z Kolna 11,<br/>
            80-864 Gdańsk
          </p>
        </div>
        <div className="md:w-2/4 flex md:flex-1 md:justify-center md:px-2 my-3 md:my-5">
          <p>
            Spółka wpisana do rejestru przedsiębiorców prowadzonego przez<br/>
            Sąd Rejonowy Gdańsk-Północ w Gdańsku,<br/>
            VII Wydział Gospodarczy Krajowego Rejestru Sądowego
          </p>
        </div>
        <div className="md:w-1/4 flex md:justify-end my-5">
          <p>
            KRS: 0000756047<br/>
            NIP: 5833327928<br/>
            REGON: 381729562
          </p>
        </div>
      </div>
    </section>
);
