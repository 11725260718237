import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import * as React from 'react';
import { CloseButton, Modal } from '../Modal/Modal';

export const thumbnailImage = graphql`
    fragment thumbnailImage on File {
        childImageSharp {
            fluid(maxWidth: 1050, maxHeight: 596, fit: CONTAIN, background: "rgba(0,0,0,0)") {
                ...GatsbyImageSharpFluid
            }
        }
    }
`;

const imgProps = {
  fadeIn: true,
};

export const Screenshots = () => {
  const data = useStaticQuery(graphql`
      query {
          image1: file(relativePath: { eq: "screenshots/auth.fakturuj.to_.png" }) {
              ...thumbnailImage
          }
          image2: file(relativePath: { eq: "screenshots/app.fakturuj.to_pulpit.png" }) {
              ...thumbnailImage
          }
          image3: file(relativePath: { eq: "screenshots/app.fakturuj.to_lista_faktur.png" }) {
              ...thumbnailImage
          }
      }
  `);

  return (
    <div className="min-h-20 w-full flex flex-row flex-wrap items-center justify-center">
      {Object.values(data).map(dataItem => {
        const [isOpen, setIsOpen] = React.useState(false);

        return (
          <div
            key={dataItem.childImageSharp.fluid.base64}
            style={{
              maxWidth: '90vw',
              width: '370px',
              objectFit: 'scale-down',
            }}
            className="m-4 block cursor-pointer shadow-md hover:shadow-lg"
          >
            <Img
              fluid={dataItem.childImageSharp.fluid}
              {...imgProps}
              onClick={() => setIsOpen(true)}
              critical={true}
              fadeIn={false}
            />
            <Modal
              isOpen={isOpen}
              onRequestClose={() => setIsOpen(false)}
            >
              <CloseButton onClick={() => setIsOpen(false)}/>
              <div
                style={{ maxHeight: '50%' }}
              >
                <Img
                  fluid={dataItem.childImageSharp.fluid}
                  {...imgProps}
                  imgStyle={{
                    maxHeight: 'calc(100vh - 250px)',
                    objectFit: 'scale-down',
                  }}
                />
              </div>
            </Modal>
          </div>
        );
      })}
    </div>
  );
};
