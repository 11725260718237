import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import Slider from 'react-slick';
import Img from 'gatsby-image';

export const Opinions = ({}) => {

  const { wolfAndOakImg, oldschoolImg, selldoImg } = useStaticQuery(graphql`
      query {
          wolfAndOakImg: file(relativePath: { eq: "opinions/logo-wolf-and-oak.png" }) {
              childImageSharp {
                  fixed(width: 100, height: 100, fit: CONTAIN, background: "rgba(0,0,0,0)") {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          oldschoolImg: file(relativePath: { eq: "opinions/logo-oldschool.png" }) {
              childImageSharp {
                  fixed(width: 100, height: 100, fit: CONTAIN, background: "rgba(0,0,0,0)") {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          selldoImg: file(relativePath: { eq: "opinions/logo-selldo.png" }) {
              childImageSharp {
                  fixed(width: 100, height: 100, fit: CONTAIN, background: "rgba(0,0,0,0)") {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `);

  const data = [
    {
      id: '1',
      message: 'FAKTURUJ.TO to był doskonały wybór, którego na pewno nie żałuję. Polecam!',
      name: 'Wolf Distillery sp. z o.o.',
      subname: 'firma',
      image: wolfAndOakImg.childImageSharp.fixed,
    },
    {
      id: '2',
      message: 'Dzięki FAKTURUJ.TO kontakty z księgową ograniczyłem do minimum, z czego bardzo się cieszę.',
      name: 'Old School BarberShop & Tattoo Ink',
      subname: 'firma',
      image: oldschoolImg.childImageSharp.fixed,
    },
    {
      id: '3',
      message: 'Prosty intuicyjny system, dzięki któremu wystawianie faktur jest "bezbolesne".',
      name: 'Selldo sp. z o.o.',
      subname: 'firma',
      image: selldoImg.childImageSharp.fixed,
    },
  ];

  return (
    <div className="overflow-hidden max-w-full">
      <Slider
        {...{
          dots: false,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
          responsive: [
            {
              breakpoint: 1280,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        }}
      >
        {data.map((item) => (
          <div
            key={item.id}
          >
            <div className="h-full flex flex-col items-center justify-center p-5 m-5 testimonial-content">
              <Img
                fixed={item.image}
                critical={true}
                fadeIn={false}
              />
              <p>
                <i className="fa fa-quote-left" style={{ color: '#5c3bd3' }}/>
                {item.message}
              </p>
              <div className="text-lg font-bold">{item.name}</div>
              <div className="text-xs">{item.subname}</div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

