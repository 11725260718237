import * as React from 'react';

type Props = {
  title?: string;
  className?: string;
  id?: string;
};

export const Section: React.FC<Props> = ({ children, title, className, id }) => (
  <section id={id} className={'container mx-auto text-center px-2 py-16 lg:py-20 ' + className}>
    <div className="section-content">
      {title && (
        <h3 className="uppercase text-3xl mb-4 font-extrabold">
          {title}
        </h3>
      )}
      {children}
    </div>
  </section>
);
